import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React from 'react'

function Table(props) {

    return (
        <>
            <Box>
                <DataGrid
                    rows={props.data}
                    columns={props.columns}
                    getRowId={props.getRowId}
                />

            </Box>
        </>
    )
}

export default Table
