import React, { useEffect, useState } from 'react'
import { deletewithheader, getWithheader } from '../../Api/Api'
import Sidebaar from '../../Component/Sidebar'
import TitleComp from '../../Component/TitleComp'
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loader from '../../Component/Loader'

function DescriptionDetail() {
    const token = localStorage.getItem("paneltoken")
    let userid = localStorage.getItem("userid")
    const [data, setdata] = useState([])
    const [loading, setloading] = useState(false)
    const navigate = useNavigate()
    const fetchdetail = async () => {
        let res = await getWithheader(`getuserdesc/${userid}`, token)
        setdata(res.data)
    }

    useEffect(() => {
        fetchdetail()
    }, [])

    const getRowId = (row) => row._id

    const columns = [
        // {
        //     field: "store_name",
        //     headerName: "Name",
        //     flex: 1,
        // },
        {
            field: "store",
            headerName: "Store",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <span>{params?.row?.store?.title}</span>
                    </>
                )
            }
        },
        {
            field: "short_detail",
            headerName: "Short Detail",
            flex: 1,
        },
        {
            field: "createdAt",
            headerName: "createdAt",
            flex: 1,
            renderCell: (params) => {
                return (moment(params.row.createdAt).format("DD MMMM YYYY") ?? "")
            }
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <button className='btn actionbtn me-3' onClick={(e) => handleedit(e, params.row)}><i class="fa-solid fa-pen-to-square"></i></button>
                        <button className='btn actionbtn' onClick={(e) => handledelete(e, params.row._id)}><i class="fa-solid fa-trash"></i></button>
                    </>
                )
            }
        },

    ]


    const handleedit = (e, item) => {
        e.preventDefault()
        navigate('/description', { state: item })
    }

    const handledelete = async (e, deleid) => {
        e.preventDefault()
        setloading(true)


        let res = await deletewithheader(`description/${deleid}`, token)
        if (res.error == "0") {
            toast.success(res.message)
            fetchdetail()
            setloading(false)
        }
        console.log(res)

    }


    return (
        <>
            {loading && <Loader />}
            <Sidebaar content={
                <>

                    <div className="col-md-12">
                        <div className="text-center">
                            <TitleComp title="Description Detail" />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <Box>
                            <DataGrid
                                rows={data}
                                columns={columns}
                                getRowId={getRowId}
                            />

                        </Box>
                    </div>
                </>
            } />
        </>
    )
}

export default DescriptionDetail
