import React, { useState, useEffect } from "react";

import { Alert, Form, FormGroup } from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";
import '../Auth/login.css'
import axios from "axios";
import logincol from "../assets/images/loginvendor.avif"

import { Bars } from "react-loader-spinner";

import { BASE_URL } from "../URL";
import logo from "../assets/images/logo.png"

import { ToastContainer, toast } from "react-toastify";
import { postapiwithoutheader } from "../Api/Api";

function Login() {
    const [animate, setAnimate] = useState(false);

    const [email, setemail] = useState("")
    const [password, setpassword] = useState("")
    const [loading, setloading] = useState(false);

    const [showpass, setshowpass] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        // Add a delay to start the animation after the component mounts
        const animationTimeout = setTimeout(() => {
            setAnimate(true);
        }, 100);

        return () => {
            clearTimeout(animationTimeout);
        };
    }, []);

    const handleshowpass = (e) => {
        e.preventDefault()
        setshowpass(!showpass)
    }

    useEffect(() => {
        localStorage.clear()
    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault()
        let requestdata = {
            email: email,
            password: password,
        }
        const res = await postapiwithoutheader(`login`, requestdata)



        if (res.details?.length > 0) {
            res.details.map((item) => {
                toast.error(item.message)
            })
        }
        if (res.error == "0") {
            if (res.type == "Vendor") {
                if (res.status != "accepted") {
                    toast.error(`Your request is pending. You will be able to log in once an admin approves your status.`);
                } else {
                    toast.success(res.message)
                    localStorage.setItem("paneltoken", res.token)
                    localStorage.setItem("userid", res._id)
                    localStorage.setItem("status", res.status)
                    navigate('/store')
                }
            } else {
                toast.error("You are not authorized to login. Only Vendor can access.");
            }
        } else {

        }
    }


    return (
        <>
            {loading && (
                <div className="overlay">
                    <Bars
                        height="80"
                        width="80"
                        color="#051322"
                        ariaLabel="bars-loading"
                        visible={true}
                    />
                </div>


            )}
            <ToastContainer />
            <section className=" loginpage">
                <div className={`container box1 ${animate ? "animate" : ""}`}>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-8">
                            <div className={`loginbox d-flex align-items-center justify-content-center w-100  shadow  rounded-3 animate-right ${animate ? "slide-in-right" : ""
                                }`}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="h-100 w-100">
                                            <img src={logincol} className="img-fluid h-100 rounded-3" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="text-center p-4">
                                            <img src={logo} className="img-fluid" width={300} alt="" />
                                            {/* <div className="mt-3">
                                                <h2 style={{ fontFamily: "cursive", fontWeight: "500" }}>Login</h2>
                                            </div> */}
                                            <Form className="text-start mt-5 formbox" autoComplete="off" onSubmit={(e) => handleSubmit(e)}>
                                                <FormGroup>
                                                    <label htmlFor="">Email</label>
                                                    <input type="email" value={email} onChange={(e) => setemail(e.target.value)} autoComplete="off" className="form-control shadow-none" />
                                                </FormGroup>
                                                <FormGroup className="mt-3">
                                                    <label htmlFor="">Password</label>
                                                    <div className="input-group">
                                                        <input value={password} onChange={(e) => setpassword(e.target.value)} type={`${showpass ? "text" : "password"}`} className="form-control shadow-none" autoComplete="off" />
                                                        <span onClick={(e) => handleshowpass(e)}>{showpass ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>} </span>
                                                    </div>
                                                </FormGroup>
                                                <button className="btn bgblue text-white mt-3 w-100">Submit</button>
                                                {/* <p className="mt-2 text-primary" style={{ fontSize: "12px", cursor: "pointer" }}>Forget Password ?</p> */}
                                            </Form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Login;
