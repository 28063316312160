import React, { useEffect, useState } from 'react'
import Sidebaar from '../../Component/Sidebar'
import TitleComp from '../../Component/TitleComp'
import Table from '../../Component/Table'
import { deletewithheader, deletewithid, getWithheader } from '../../Api/Api'
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BASE_URL } from '../../Api/BaseUrl'
import axios from 'axios'
import Loader from '../../Component/Loader'
import { Button, Modal } from 'react-bootstrap'

function OfferList() {
    const [data, setdata] = useState([])
    const [loading, setloading] = useState(false)
    const [codedata, setcodedata] = useState([])
    let userid = localStorage.getItem("userid")
    const handleget = async () => {
        const res = await getWithheader(`getuseroffer?id=${userid}`)
        console.log(res.data)
        setdata(res.data)
    }
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const token = localStorage.getItem("token")
    const headers = {
        Authorization: `Bearer ${token}`
    }

    const navigate = useNavigate()



    useEffect(() => {
        handleget()
    }, [])

    const columns = [
        {
            field: "name",
            headerName: "Store Name",
            flex: 1,
        },
        {
            field: "code",
            headerName: "Code",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <button className='btn actionbtn  text-white ' onClick={(e) => { setShow(true); setcodedata(params.row.generated_codes) }}>Code</button>
                    </>
                )
            }
        },
        {
            field: "description",
            headerName: "Description",
            flex: 1,
        },
        {
            field: "deleted_price",
            headerName: "Deleted Price",
            flex: 1,
        },
        {
            field: "createdAt",
            headerName: "createdAt",
            flex: 1,
            renderCell: (params) => {
                return (moment(params.row.createdAt).format("DD MMMM YYYY") ?? "")
            }
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <button className='btn actionbtn me-3' onClick={(e) => handleedit(e, params.row)}><i class="fa-solid fa-pen-to-square"></i></button>
                        <button className='btn actionbtn' onClick={(e) => handledelete(e, params.row._id)}><i class="fa-solid fa-trash"></i></button>
                    </>
                )
            }
        },

    ]
    const handledelete = async (e, deleid) => {
        e.preventDefault()
        setloading(true)
        let requestdata = {
            data: {
                id: deleid
            }
        }
        axios.delete(`${BASE_URL}offer`, requestdata, { headers }).then((res) => {
            console.log(res)
            if (res.data.error == "0") {
                toast.success(res.data.message)
                handleget()
                setloading(false)
            }
        }).catch((e) => {
            toast.error(e.data.message)
            setloading(false)
        })
        // .finally((e) => {
        //     setloading(false)
        // })
    }

    const handleedit = (e, item) => {
        e.preventDefault()
        navigate('/addoffer', { state: item })
    }


    const getRowId = (row) => row._id
    return (
        <>
            {loading && <Loader />}
            <Sidebaar content={
                <>
                    <div className="col-md-12">
                        <div className="text-center">
                            <TitleComp title="Offer List" />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <Table data={data} columns={columns} getRowId={getRowId} />
                    </div>

                    <Modal
                        show={show}
                        size='lg'
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Code List</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">

                                {codedata
                                    .filter(item => item.status === "active") // Filter for active items
                                    .map((item) => {
                                        return (
                                            <div className="col-md-6 mb-4" key={item._id}>
                                                <div className="bg-white shadow p-4 rounded-3 h-100">
                                                    <h5 className="text-primary">Code: {item.code}</h5>
                                                    <p className="mb-1"><strong>Status:</strong> {item.status}</p>
                                                    <div className="mb-2">
                                                        <strong>Generated By:</strong>
                                                        <div className="text-muted">
                                                            <span>{item.user.name}</span> - <span>{item.user.email}</span>
                                                        </div>
                                                    </div>
                                                    <p className="text-muted"><strong>Valid Until:</strong> {new Date(item.valid_until).toLocaleString()}</p>

                                                </div>
                                            </div>
                                        );
                                    })}

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary">Understood</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            } />
        </>
    )
}

export default OfferList
