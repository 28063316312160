import React from 'react'
import Sidebaar from '../../Component/Sidebar'
import TitleComp from '../../Component/TitleComp'
import { Table } from 'react-bootstrap'

const ApplyCoupon = () => {
    // const columns = [
    //     {
    //         field: "Code",
    //         headerName: "Code",
    //         flex: 1,
    //         renderCell: (params) => {
    //             return (
    //                 <><span>{params?.row.category?.name}</span></>
    //             )
    //         }
    //     },
    // ]
    return (
        <>
            <Sidebaar
                content={
                    <section>
                        <div className="container">
                            <div className="row gy-3">
                                <div className="col-md-4">
                                    <label htmlFor="name">Coupon Code</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <button className="btn bgblue text-white px-5" type="submit">
                                        Apply
                                    </button>
                                </div>
                                {/* <div className="col-md-12">
                                    <div className="col-md-12">
                                        <Table  columns={columns}  />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </section>
                }
            />
        </>
    )
}

export default ApplyCoupon