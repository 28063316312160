import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Form, FormGroup } from 'react-bootstrap';
import Sidebaar from '../Component/Sidebar';
import { useGeolocated } from 'react-geolocated';

function AddLocation() {
    const [map, setMap] = useState(null);
    const [infoWindow, setInfoWindow] = useState(null);
    const [latlong, setlatlong] = useState("")
    const [address, setAddress] = useState("")
    const [state, setstate] = useState("")
    const [city, setcity] = useState("")
    const [fulladdress, setfulladdress] = useState("")
    const [second, setsecond] = useState([])
    const [fourth, setfourth] = useState([])

    const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY



    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
        });


    const fetchAddress = async () => {
        try {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlong ? latlong.lat : coords.latitude},${latlong ? latlong.lng : coords.longitude}&key=${googleApiKey}`);
            if (response.data.results.length > 0) {
                setAddress(response.data.results[0]);
                console.log("latlogn", response.data.results[0])

            } else {
                setAddress('Address not found');
            }
        } catch (error) {
            console.error('Error fetching the address:', error);
            setAddress('Error fetching the address');
        }
    };

    

    useEffect(() => {
        if (address) {
            // Extract the required components
            console.log(address.address_components)
            const secondFromLast = address.address_components[address.address_components?.length - 3];
            const fourthFromLast = address.address_components[address.address_components?.length - 5];

            console.log(secondFromLast)
            console.log(fourthFromLast)

            setstate(secondFromLast.long_name)
            setcity(fourthFromLast.long_name)
            setfulladdress(address.formatted_address)
        }
    }, [address])



    useEffect(() => {
        if (!coords) return;

        async function loadMap() {
            try {
                const { google } = window; // Ensure google is available
                let mapCenter = { lat: coords.latitude, lng: coords.longitude };
                const mapInstance = new google.maps.Map(document.getElementById("map"), {
                    zoom: 4,
                    center: mapCenter,
                });
                setMap(mapInstance);

                // Create the initial InfoWindow.
                const initialInfoWindow = new google.maps.InfoWindow({
                    content: "Click the map to get Lat/Lng!",
                    position: mapCenter,
                });
                initialInfoWindow.open(mapInstance);
                setInfoWindow(initialInfoWindow);

                // Configure the click listener.
                mapInstance.addListener("click", (mapsMouseEvent) => {
                    // Close the current InfoWindow.
                    infoWindow?.close();

                    // Create a new InfoWindow.
                    const newInfoWindow = new google.maps.InfoWindow({
                        position: mapsMouseEvent.latLng,
                    });

                    const latLngData = mapsMouseEvent.latLng.toJSON();
                    newInfoWindow.setContent(
                        JSON.stringify(latLngData, null, 2)
                    );
                    newInfoWindow.open(mapInstance);

                    setlatlong(latLngData);
                    setInfoWindow(newInfoWindow);
                });
            } catch (error) {
                console.error("Error loading Google Maps API:", error);
            }
        }

        loadMap();



        // Cleanup function
        return () => {
            // Clean up map and infoWindow objects if necessary
        };
    }, [coords]);

    useEffect(() => {
        if (latlong || coords) {
            fetchAddress()
        }
    }, [latlong, coords])


    async function getCurrentLocation() {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        resolve({ lat: latitude, lng: longitude });
                    },
                    (error) => {
                        console.error("Error getting current location:", error);
                        // Default to a fallback location if getting current location fails
                        resolve({ lat: 20.5937, lng: 78.9629 });
                    }
                );
            } else {
                console.error("Geolocation is not supported by this browser.");
                // Default to a fallback location if geolocation is not supported
                resolve({ lat: 20.5937, lng: 78.9629 });
            }
        });
    }



    return (
        <>
            <Sidebaar content={<>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-12">
                                <div className="mt-3">
                                    <div id="map" style={{ width: "100%", height: "400px" }}></div>;
                                </div>

                            </div>

                            <div className="col-md-4">
                                <Form>
                                    <div className="row gy-3">
                                        <div className="col-md-6">
                                            <FormGroup>
                                                <label htmlFor="">
                                                    State
                                                </label>
                                                <input type="text" value={state} onChange={(e) => setstate(e.target.value)} className='form-control' />
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-6">
                                            <FormGroup>
                                                <label htmlFor="">
                                                    City
                                                </label>
                                                <input type="text" value={city} onChange={(e) => setcity(e.target.value)} className='form-control' />
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-12">
                                            <FormGroup>
                                                <label htmlFor="">
                                                    Address
                                                </label>
                                                <textarea name="" className='form-control' value={fulladdress} onChange={(e) => setfulladdress(e.target.value)} id=""></textarea>
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-12">
                                            <button className='btn custombtn'>Submit</button>
                                        </div>
                                    </div>
                                </Form>
                            </div>

                        </div>
                    </div>
                </section>
            </>} />
        </>
    )
}

export default AddLocation
