import React, { useEffect, useState } from 'react';
import Sidebaar from '../../Component/Sidebar';
import { Form } from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useLocation, useNavigate } from 'react-router-dom';
import { getWithheader, getwithoutheader, postwithheader, putwithheader } from '../../Api/Api';
import Loader from '../../Component/Loader';
import { toast } from 'react-toastify';
import {
    CitySelect,
    StateSelect,
} from "react-country-state-city";

function AddOffer() {
    const { state } = useLocation();
    const [name, setName] = useState('');
    const [file, setfile] = useState(null);
    const [code, setCode] = useState('');
    const [type, settype] = useState('')
    const [desc, setDesc] = useState('');
    const [delPrice, setDelPrice] = useState('');
    const [realPrice, setRealPrice] = useState('');
    const [discountAmount, setDiscountAmount] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState('');
    const [minPurchase, setMinPurchase] = useState('');
    const [maxDiscount, setMaxDiscount] = useState('');
    const [details, setDetails] = useState('');
    const [validity, setValidity] = useState('');
    const [redeem, setRedeem] = useState('');
    const [terms, setTerms] = useState('');
    const [loading, setLoading] = useState(false);
    const [membershipData, setMembershipData] = useState([]);
    const [membership, setMembership] = useState('');
    const [storeData, setStoreData] = useState([]);
    const [store, setStore] = useState('');
    const [statedata, setstatedata] = useState('');
    const [citydata, setcitydata] = useState("");
    const [stateid, setstateid] = useState(null);
    const [startdate, setstartdate] = useState("");
    const [expirydate, setexpirydate] = useState("");
    const [usagelimit, setusagelimit] = useState("");
    const token = localStorage.getItem('token');
    const userid = localStorage.getItem('userid');
    const [sector, setsector] = useState("")
    const [sectordata, setsectordata] = useState([]);
    const handlesectorget = async () => {
        try {
            const res = await getwithoutheader('states');
            console.log(res.data);
            setsectordata(res.data);

        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };
    const navigate = useNavigate();

    const handleStoreFetch = async () => {
        let res = await getWithheader(`vendorstore/${userid}`, token);
        setStoreData(res.data);
    };

    const handleMembershipFetch = async () => {
        let res = await getwithoutheader('membership');
        setMembershipData(res.data);
    };

    useEffect(() => {
        handleStoreFetch();
        handleMembershipFetch();
        handlesectorget();
    }, []);

    useEffect(() => {
        if (state) {

            setName(state.name || '');
            setfile(state.image || '');
            setCode(state.code || '');
            setDesc(state.description || '');
            setDelPrice(state.deleted_price || '');
            if (state.alluser) {
                setMembership("alluser")
            } else {
                setMembership(state.membership);
            }
            setStore(state.store || '');
            setstatedata(state.state)
            setcitydata(state.city)

            settype(state.type || '');
            setstartdate(state.start_date.substring(0, 10) || '');
            setexpirydate(state.expiry_date.substring(0, 10) || '');
            setusagelimit(state.usage_limit || '');
            setDetails(state.offer_detail || '');
            setValidity(state.offer_validity || '');
            setRedeem(state.offer_redeem || '');
            setTerms(state.offer_terms || '');
            setRealPrice(state.real_price || '');
            setDiscountAmount(state.discount_amount || '');
            setDiscountPercentage(state.discount_percentage || '');
            setMinPurchase(state.minimum_purchase || '');
            setMaxDiscount(state.maximum_discount || '');
            // setDetails(state.offer_detail?.[0]?.detail || '');
            // setValidity(state.offer_detail?.[0]?.validity || '');
            // setRedeem(state.offer_detail?.[0]?.redeem || '');
            // setTerms(state.offer_detail?.[0]?.terms || '');
        }
    }, [state]);

    const handleEditorChange = (setter) => (event, editor) => {
        const data = editor.getData();
        setter(data);
    };
    const handlefile = (e) => {
        let selectedfile = e.target.files[0]
        setfile(selectedfile);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formdata = new FormData();
        formdata.append("name", name);

        formdata.append("image", file);

        formdata.append("store", store);
        formdata.append("type", type);
        formdata.append("state", statedata);
        formdata.append("city", citydata);
        formdata.append("created_by", userid);
        formdata.append("description", desc);
        formdata.append("deleted_price", delPrice);
        formdata.append("real_price", realPrice);
        formdata.append("sector",sector);
        if (membership === "alluser") {
            formdata.append("alluser", true);
        } else {
            formdata.append("membership", membership);
            formdata.append("alluser", false);
        }
        formdata.append("discount_amount", discountAmount);
        formdata.append("discount_percentage", discountPercentage);
        formdata.append("minimum_purchase", minPurchase);
        formdata.append("maximum_discount", maxDiscount);
        formdata.append("start_date", startdate);
        formdata.append("expiry_date", expirydate);
        formdata.append("usage_limit", usagelimit);
        formdata.append("offer_detail", details);
        formdata.append("offer_validity", validity);
        formdata.append("offer_redeem", redeem);
        formdata.append("offer_terms", terms);
        try {
            let res;
            if (state) {
                formdata.append("id", state._id);
                res = await putwithheader('offer', token, formdata);
            } else {
                res = await postwithheader('offer', token, formdata);
            }

            if (res.error === "0") {
                toast.success(res.message);
                navigate("/offerlist");
            }
            setLoading(false);
        } catch (error) {
            console.error('Error submitting offer:', error);
            setLoading(false);
        }

    }

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setLoading(true);

    //     let requestData = {
    //         created_by: userid,
    //         name,
    //         code,
    //         store,
    //         type,
    //         state: statedata,
    //         city: citydata,
    //         description: desc,
    //         deleted_price: delPrice,
    //         real_price: realPrice,
    //         ...(membership === "alluser" ? { alluser: true } : { membership, alluser: false }),

    //         discount_amount: discountAmount,
    //         discount_percentage: discountPercentage,
    //         minimum_purchase: minPurchase,
    //         maximum_discount: maxDiscount,
    //         start_date: startdate,
    //         expiry_date: expirydate,
    //         usage_limit: usagelimit,
    //         offer_detail: [
    //             {
    //                 detail: details,
    //                 validity,
    //                 redeem,
    //                 terms
    //             }
    //         ]
    //     };

    //     try {
    //         let res;
    //         if (state) {
    //             requestData.id = state._id;
    //             res = await putwithheader('offer', token, requestData);
    //         } else {
    //             res = await postwithheader('offer', token, requestData);
    //         }
    //         if (res.error === "0") {
    //             toast.success(res.message);
    //             navigate("/offerlist");
    //         }
    //         setLoading(false);
    //     } catch (error) {
    //         console.error('Error submitting offer:', error);
    //         setLoading(false);
    //     }
    // };

    return (
        <>
            {loading && <Loader />}
            <Sidebaar
                content={
                    <section>
                        <div className="container">
                            <Form onSubmit={handleSubmit}>
                                <div className="row gy-3">
                                    <div className="col-md-4">
                                        <label htmlFor="name">Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            id="name"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="">Select Sector</label>
                                        <select name="" value={sector} onChange={(e) => setsector(e.target.value)} className='form-control form-select' id="">
                                            <option value="">Select Sector</option>
                                            {sectordata?.map((state) => (
                                                <option key={state.sectorCode} value={state.sectorCode}>
                                                    {state.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="image">Image</label>
                                        <input
                                            type="file"
                                            className="form-control"

                                            onChange={handlefile}
                                            id="file"
                                        />
                                    </div>
                                    {/* {state &&
                                        <div className="col-md-4">


                                            <>
                                                <label htmlFor="">Selected State</label>
                                                <input value={statedata} className='form-control ' disabled></input>
                                            </>
                                        </div>
                                    }
                                    <div className="col-md-4">
                                        <label htmlFor="name">Select State</label>
                                        <StateSelect
                                            countryid={101}
                                            value={statedata}
                                            onChange={(e) => {
                                                setstatedata(e.name);    // Capture state name
                                                setstateid(e.id);        // Capture state ID
                                            }}
                                            placeHolder="Select State"
                                        />
                                    </div>
                                    {state &&
                                        <div className="col-md-4">


                                            <>
                                                <label htmlFor="">Selected City</label>
                                                <input value={citydata} className='form-control ' disabled></input>
                                            </>
                                        </div>
                                    }
                                    <div className="col-md-4">
                                        <label htmlFor="name">Select City</label>
                                        <CitySelect
                                            countryid={101}
                                            stateid={stateid}
                                            value={citydata}
                                            onChange={(e) => setcitydata(e.name)}
                                            placeHolder="Select City"
                                        />
                                    </div> */}
                                    {/* <div className="col-md-4">
                                        <label htmlFor="code">Code</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={code}
                                            onChange={(e) => setCode(e.target.value)}
                                            id="code"
                                        />
                                    </div> */}
                                    <div className="col-md-4">
                                        <label htmlFor="membership">Membership</label>
                                        <select
                                            value={membership}
                                            onChange={(e) => setMembership(e.target.value)}
                                            className="form-control form-select"
                                            id="membership"
                                        >
                                            <option value="">Select Membership</option>
                                            <option value="alluser">All Users</option>
                                            {membershipData.map((item) => (
                                                <option key={item._id} value={item._id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="store">Store</label>
                                        <select
                                            value={store}
                                            onChange={(e) => setStore(e.target.value)}
                                            className="form-control form-select"
                                            id="store"
                                        >
                                            <option value="">Select Store</option>
                                            {storeData.map((item) => (
                                                <option key={item._id} value={item._id}>
                                                    {item.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="description">Description</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={desc}
                                            onChange={(e) => setDesc(e.target.value)}
                                            id="description"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="type">Discount Type</label>
                                        <select name="" value={type} onChange={(e) => settype(e.target.value)} className='form-control form-select' id="">
                                            <option value="">Select Type</option>
                                            <option value="Percent">Percent</option>
                                            <option value="Value">Value</option>
                                        </select>
                                    </div>
                                    {type == "Value" && <div className="col-md-4">
                                        <label htmlFor="discountAmount">Discount Amount</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={discountAmount}
                                            onChange={(e) => setDiscountAmount(e.target.value)}
                                            id="discountAmount"
                                        />
                                    </div>}
                                    {type == "Percent" && <div className="col-md-4">
                                        <label htmlFor="discountPercentage">Discount Percentage</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={discountPercentage}
                                            onChange={(e) => setDiscountPercentage(e.target.value)}
                                            id="discountPercentage"
                                        />
                                    </div>}
                                    <div className="col-md-4">
                                        <label htmlFor="minPurchase">Minimum Purchase</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={minPurchase}
                                            onChange={(e) => setMinPurchase(e.target.value)}
                                            id="minPurchase"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="maxDiscount">Maximum Discount</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={maxDiscount}
                                            onChange={(e) => setMaxDiscount(e.target.value)}
                                            id="maxDiscount"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="startDate">Start Date</label>
                                        <input
                                            type="date" // Change type to date
                                            className="form-control"
                                            value={startdate} // Make sure this state holds a valid date string (YYYY-MM-DD)
                                            onChange={(e) => setstartdate(e.target.value)} // This will update the state with the selected date
                                            id="startDate"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="expiryDate">End Date</label>
                                        <input
                                            type="date" // Change type to date
                                            className="form-control"
                                            value={expirydate} // Make sure this state holds a valid date string (YYYY-MM-DD)
                                            onChange={(e) => setexpirydate(e.target.value)} // This will update the state with the selected date
                                            id="expiryDate"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="expiryDate">Coupon Limit</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={usagelimit}
                                            onChange={(e) => setusagelimit(e.target.value)}
                                            id="usageLimit"
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="details">Details</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={details}
                                            onChange={handleEditorChange(setDetails)}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="validity">Validity</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={validity}
                                            onChange={handleEditorChange(setValidity)}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="redeem">Redeem Information</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={redeem}
                                            onChange={handleEditorChange(setRedeem)}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label htmlFor="terms">Terms & Conditions</label>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={terms}
                                            onChange={handleEditorChange(setTerms)}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <button className="btn bgblue text-white px-5" type="submit">
                                            {state ? "Update Offer" : "Add Offer"}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </section>
                }
            />
        </>
    );
}

export default AddOffer;
