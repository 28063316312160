import React, { useState, useEffect } from "react";

import { Link, NavLink, useNavigate } from "react-router-dom";

// import logo from "../assets/icon/celdon-logo.png";

import axios from "axios";
import "../assets/css/sidebar.css"

import shortlogo from "../assets/images/a.png"
import logo from "../assets/images/logo.png"
import { ToastContainer } from "react-toastify";



const Sidebaar = ({ content }) => {
    const status = localStorage.getItem("status")
    const [isCollapsed, setIsCollapsed] = useState(
        localStorage.getItem("isCollapsed") === "true"
    );
    const navigate = useNavigate();

    const handleToggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
        localStorage.setItem("isCollapsed", isCollapsed ? "false" : "true");
    };

    useEffect(() => {
        checkCollapse();
    }, []);

    const checkCollapse = () => {
        if (localStorage.getItem("isCollapsed") === "true") {
            setIsCollapsed(true);
        }
    };

    //   useEffect(() => {
    //     if (!localStorage.getItem("token")) {
    //       navigate("/");
    //     }
    //   }, []);

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            // Handle specific error codes or situations here

            if (error.response && error.response.status === 403) {
                // Handle 403 error without logging it
                // navigate("/login");

                navigate("/");
            }

            return Promise.reject(error);
        }
    );

    const handlelogout = () => {
        // Clear cookies
        document.cookie.split(";").forEach((c) => {
            document.cookie = c
                .replace(/^ +/, "")
                .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
        });

        localStorage.clear();
    };


    const menuData = [
        // {
        //     name: "Dashboard", icon: <i class="fa-solid fa-house"></i>, link: "/dashboard"
        // },
        // {
        //     name: "Location", icon: <i class="fa-solid fa-users"></i>, link: "/addlocation"
        // },
        // {
        //     name: "Magzine", icon: <i class="fa-solid fa-book"></i>, link: "/magzine"
        // },
        {
            name: "Create Store", icon: <i class="fa-solid fa-store"></i>, link: "/store"
        },
        {
            name: "Description", icon: <i class="fa-solid fa-list"></i>, link: "/description"
        },
        {
            name: "Description List", icon: <i class="fa-solid fa-table-list"></i>, link: "/descriptionlist"
        },
        {
            name: "Product", icon: <i class="fa-solid fa-box"></i>, link: "/product"
        },
        {
            name: "Product List", icon: <i class="fa-solid fa-boxes-stacked"></i>, link: "/productlist"
        },
        {
            name: "Add Offer", icon: <i class="fa-solid fa-ticket"></i>, link: "/addoffer"
        },
        {
            name: "Offer List", icon: <i class="fa-solid fa-money-bill"></i>, link: "/offerlist"
        },
        {
            name: "Apply Coupons", icon: <i class="fa-solid fa-money-bill"></i>, link: "/applycoupons"
        },
        {
            name: "Opening Hours", icon: <i class="fa-solid fa-clock"></i>, link: "/openinghour"
        },
        // {
        //     name: "Add Events", icon: <i class="fa-solid fa-calendar-days"></i>, link: "/event"
        // },
        // {
        //     name: "Event List", icon: <i class="fa-solid fa-calendar-week"></i>, link: "/eventlist"
        // },
        {
            name: "Logout", icon: <i class="fa-solid fa-right-from-bracket"></i>, link: "/", isLogout: true
        },
    ]

    return (

        <div className="w-100 d-flex">
            <ToastContainer />
            <div className={`sidebarContainer ${isCollapsed ? "collapsed" : ""}`}>
                <div className="w-100 h-100 position-relative">
                    <div className="sidebar-header d-flex justify-content-center">
                        <Link
                            className="btn text-dark bg-white"
                            id="closeSidebar"
                            onClick={handleToggleSidebar}
                        >
                            {isCollapsed ? (
                                <i class="fa-solid fa-xmark fs-5"></i>
                            ) : (
                                <i class="fa-solid fa-bars fs-5"></i>
                            )}
                        </Link>
                        <Link className="d-md-none d-block desktoplogo ">
                            <img
                                src={logo}
                                className="img-fluid mobile_fullname_logo"
                                alt=""
                            />
                        </Link>
                        <Link className="d-none d-md-block ">
                            {isCollapsed ? (
                                <img
                                    src={shortlogo}
                                    alt=""
                                    className="img-fluid  mt-0"
                                    style={{ width: "40px" }}
                                />
                            ) : (
                                <img src={logo} alt="" className="img-fluid beat_logo" />
                            )}

                            {/* <img src={logo} className="img-fluid fullname_logo" alt="" /> */}
                        </Link>
                    </div>
                    <div className="sidebar-content" id="Sidebar_nav">
                        <ul className="list-unstyled">



                            {menuData.map((item, index) => (
                                <li key={index}>
                                    <NavLink
                                        to={item.link}
                                        className="d-flex  align-items-center gap-2 Sidebar_link"
                                        onClick={(e) => item.isLogout && handlelogout(e)}
                                    >
                                        <span className="icon">{item.icon}</span>
                                        <p
                                            className={`tool-tip ${!isCollapsed ? "" : "nonetooltip"}`}
                                        >
                                            {item.name}
                                        </p>
                                    </NavLink>

                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="sidebar-footer ">{/* Sidebar footer */}</div>
                </div>
            </div>
            <div className={`mainContainer  ${isCollapsed ? "collapsed" : ""}`}>
                <div className="w-100 h-100">
                    <div className="w-100 sticky-top">
                        <nav className="w-100 topbarNav nav-bg">
                            <div className="container-fluid">
                                <div className="row align-items-center">
                                    <div className="col-md-12 col-4">
                                        <Link
                                            className="btn border-0 shadow-none makeExtend bg-white"
                                            onClick={handleToggleSidebar}
                                        >
                                            {isCollapsed ? (
                                                <i class="fa-solid fa-xmark fs-5"></i>
                                            ) : (
                                                <i class="fa-solid fa-bars fs-5"></i>
                                            )}
                                        </Link>
                                        <div className="mobilelogo">
                                            <Link className="d-md-none d-block">
                                                <img
                                                    src={logo}
                                                    className="img-fluid mobile_fullname_logo"
                                                    alt=""
                                                />
                                            </Link>
                                            <Link className="d-none d-md-block">
                                                <img
                                                    src={logo}
                                                    alt=""
                                                    className="img-fluid beat_logo"
                                                />
                                                <img
                                                    src={logo}
                                                    className="img-fluid fullname_logo"
                                                    alt=""
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                        <div className="container-fluid" style={{ marginTop: "90px" }}>
                            <div className="row gy-3 mt-3">{content}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebaar;
