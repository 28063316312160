import React, { useEffect, useState } from 'react'
import Loader from '../../Component/Loader'
import Sidebaar from '../../Component/Sidebar'

import TitleComp from '../../Component/TitleComp'
import { Form } from 'react-bootstrap'
import { getWithheader } from '../../Api/Api'

function Openinghour() {
    const [loading, setloaing] = useState(false)
    const [storedata, setstoredata] = useState([])
    let userid = localStorage.getItem("userid")
    let token = localStorage.getItem("paneltoken")


    const handleget = async () => {
        let res = await getWithheader(`vendorstore/${userid}`, token)
        setstoredata(res.data)
    }

    useEffect(() => {
        handleget()
    }, [])
    return (
        <>
            {loading && <Loader />}
            <Sidebaar content={
                <>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="text-center">
                                        <TitleComp title="Opening Hours" />
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <p className='text-danger'>*You can write the time like this: 8am - 9pm, or if your store is close, then write closed.</p>

                                </div>
                            </div>
                            <Form>
                                <div className="row gy-4">
                                    <div className="col-md-4">
                                        <label htmlFor="">Select Store</label>
                                        <select name="" className='form-control form-select' id="">
                                            <option value="">--Select Store--</option>
                                            {storedata.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item._id}>{item.title}</option>

                                                    </>
                                                )
                                            })}

                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="monday">Monday</label>
                                        <input type="text" id="monday" className='form-control' placeholder="8am to 9pm" />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="tuesday">Tuesday</label>
                                        <input type="text" id="tuesday" className='form-control' placeholder="8am to 9pm" />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="wednesday">Wednesday</label>
                                        <input type="text" id="wednesday" className='form-control' placeholder="8am to 9pm" />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="thursday">Thursday</label>
                                        <input type="text" id="thursday" className='form-control' placeholder="8am to 9pm" />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="friday">Friday</label>
                                        <input type="text" id="friday" className='form-control' placeholder="8am to 9pm" />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="saturday">Saturday</label>
                                        <input type="text" id="saturday" className='form-control' placeholder="8am to 9pm" />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="sunday">Sunday</label>
                                        <input type="text" id="sunday" className='form-control' placeholder="8am to 9pm" />
                                    </div>
                                    <div className="col-md-12">
                                        <button class="btn bgblue text-white px-5" type="submit">Add Opening Hour</button>
                                    </div>
                                </div>
                            </Form>

                        </div>
                    </section>
                </>
            } />


        </>
    )
}

export default Openinghour